







import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  name: "ChoiceItemAttachment",
})
export default class ChoiceItemAttachment extends Vue {
  @Prop({required: false, default: ""})
  url!: string;

  get isImage() {
    return new RegExp(/(\.(jpg|jpeg|png|bmp|gif))$/).test(this.url);
  }
  get isVideo() {
    return new RegExp(/(\.(mp4))$/).test(this.url);
  }
}
