































































































import {Component, Prop, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {ExamPaperMiniDto, ExamSourceType, QuestionAnalysisDto} from "@/api/appService";
import ChoiceItemAttachment from "@/components/ChoiceItemAttachment/index.vue";


@Component({
  name: "ExamPaperDetail",
  components: {
    PagedTableView, ChoiceItemAttachment
  },
})
export default class ExamPaperDetail extends Vue {
  @Prop() userId!: number;

  @Prop() trainId!: number;

  @Prop() starRatingId!: number;

  paper: ExamPaperMiniDto = {};

  analysisList: QuestionAnalysisDto[] = [];

  created() {
    if (this.trainId > 0) {
      api.examQuestion.getLatestTrainPaperId({userId: this.userId, trainId: this.trainId}).then(res => {
        this.paper = res;
        if (this.paper && this.paper.id) {
          this.getDetail();
        }
      });
    } else if (this.starRatingId > 0) {
      api.examQuestion.getLatestTrainPaperId({
        userId: this.userId,
        trainId: this.starRatingId,
        sourceType: ExamSourceType.StarRating
      }).then(res => {
        this.paper = res;
        if (this.paper && this.paper.id) {
          this.getDetail();
        }
      });
    }
  }


  getDetail() {
    api.examQuestion.getQuestionAnalysisList({paperId: this.paper.id}).then(res => {
      this.analysisList = res;
    })
  }
}
